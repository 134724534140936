import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/circleci/choice/src/components/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "isBox": true,
      "flipKey": 120
    }}><h1 parentName="div">{`Choice`}</h1>
      <div className="subtitle">Berlin’deki ilköğretim okulları arasından seçim yapılmasına ilişkin bir araştırma projesi.</div>
      <p parentName="div">{`Sayın Anne ve Babalar, Sayın Veliler,`}</p>
      <p parentName="div">{`Çocuğunuzun ilkokula başlamasıyla birlikte, Eylül 2015’ten itibaren siz ve çocuğunuz için yeni, heyecan verici ve önemli bir dönem açılıyor. Araştırma projemiz kapsamında, okul seçiminizi kimin ve neyin etkilediği ve çocuğunuzun müstakbel okulundan beklentilerinizle ilgileniyoruz.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 121
    }}>
      <h1 parentName="div">{`Choice nedir?`}</h1>
      <p parentName="div">{`Choice. Okul seçimi ve sosyo-kültürel uyum, Alman Araştırmalar Birliğinin (DFG) Berlin’de, 2017 sonbaharına kadar finanse ettiği bir araştırma projesidir. Bu araştırma ilkokullarda, Berlin Humboldt Üniversitesi’nin ve Münster Westfälische Wilhelms Üniversitesi’nin eğitim araştırmacıları tarafından yürütülmektedir`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 122
    }}>
      <h1 parentName="div">{`Choice neyi inceler?`}</h1>
      <p parentName="div">{`İnceleme aşağıdaki soruları ele alır: Çocuğu için kim, hangi okulu neden seçiyor? Kişi neden seçmiyor? Anne ve babaların çocuğun müstakbel okulundan beklentileri nelerdir? Kısacası, proje anne ve babaların okul kalitesi bağlamında belirledikleri özellikler (örn. okulun havası veya ders yöntemleri) temelindeki okul seçme nedenlerini ve okul seçimi açısından diğer önemli hususları (örn. okul yolunun uzunluğu) kapsar. Bunun dışında, okul kalitesiyle ilgili bu özelliklerin çocuklarının müstakbel okulu bağlamında bütün anne ve babaların gözündeki önemi de araştırılacaktır. Bu öneme dayanarak, okul ve okul yönetimlerinin okul seçim sürecinde nasıl bir rol oynadıkları sorusu cevaplandırıl-maya çalışılacaktır; şöyle ki: Okullar öğrencilerini nasıl seçiyor?`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 123
    }}>
      <h1 parentName="div">{`Araştırmanın amacı nedir?`}</h1>
      <p parentName="div">{`Bu araştırmanın amacı, anne ve babanın - okulla ilgili - yetiştirme ve eğitim kavramları ve çok yönlü pedagojik, dini veya toplumsal yönelimleri ve önerileriyle farklı okul türlerinin ya da okul özelliklerinin uyumunu göstermek ve bu uyumun tek tek okulların ve genel olarak okul sisteminin gelişimi üzerindeki etkisini incelemektir.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 124
    }}>
      <h1 parentName="div">{`Araştırma nasıl yürütülecek?`}</h1>
      <p parentName="div">{`Araştırma kapsamında, Berlin’deki anne ve babalara ve okul yönetimlerine sözlü veya yazılı anket soruları yöneltilecektir. Anketler 2015 ve 2016 yıllarında, toplam 200 resmi ve özel ilkokulda yürütülecek, isimsiz olacak ve çevrimiçi ya da kadın proje görevlileri aracılığıyla gerçekleştirilecektir.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 125
    }}>
      <h1 parentName="div">{`Bu araştırmada yeni olan nedir?`}</h1>
      <p parentName="div">{`Bu araştırma, anne ve babaların okul seçim nedenlerini ve ‘iyi okul’ beklentilerini göz önünde bulunduran, anne ve babaların okul seçme nedenlerindeki benzerlikleri ve farklılıkları ve ayrıca resmi ve özel okulların öğrenci seçme süreçlerini kıyaslayan ilk bilimsel araştırmadır.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 126
    }}>
      <h1 parentName="div">{`Verdiğim bilgiler ne olacak?`}</h1>
      <p parentName="div">{`Anket gönüllülük temelinde gerçekleştiril-mekte olup, ankete katılımınız bütünüyle isimsizdir. Bütün veriler kesinlikle gizli tutulacak ve sadece bilimsel amaçlar için kullanılacaktır. Veriler üçüncü kişilere iletilmeyecektir. Kamusal fonlar ile finanse edilen araştırma, Berlin Veri Koruma Yasası, Pedagoji Derneklerinin ve Alman Araştırmalar Birliğinin (DFG) yönetmelikleri ile tamamen uyumlu biçimde yürütülmektedir.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 127
    }}>
      <h1 parentName="div">{`Araştırmaya katılmanız size ve çocuğunuzun okuluna ne kazandıracak?`}</h1>
      <p parentName="div">{`Sizin ve okulun bilgisine sunulacak araştırma projesi sonuçlarının yanı sıra, katılan tüm okullar arasında yapılacak çekilişte toplam 4.000,- Avro değerinde kitap ve medya hediye çekleri dağıtılacaktır. Lütfen katılın. Yanıtlarınızı bekliyoruz.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 128
    }}>
      <h1 parentName="div">{`Başka sorularınız mı var?`}</h1>
      <p parentName="div">{`Başka sorunuz varsa, bize yazabilir ya da telefon edebilirsiniz.`}</p>
      <p parentName="div">{`Prof. Dr. Thomas Koinzer`}<br parentName="p"></br>{`
`}{`Humboldt-Universität zu Berlin`}<br parentName="p"></br>{`
`}{`Kultur-, Sozial- und Bildungswissenschaftliche Fakultät`}<br parentName="p"></br>{`
`}{`Institut für Erziehungswissenschaften`}<br parentName="p"></br>{`
`}{`Systematische Didaktik und Unterrichtsforschung`}<br parentName="p"></br>{`
`}{`Unter den Linden 6`}<br parentName="p"></br>{`
`}{`10099 Berlin`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
          "href": "mailto:thomas.koinzer@hu-berlin.de"
        }}>{`thomas.koinzer@hu-berlin.de`}</a><br parentName="p"></br>{`
`}{`030/20931633`}</p>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      